.dropzone{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5em;
    outline: 0.125em dotted var(--color-stroke-light);
}

.droptarget{
    outline: 0.125em dashed var(--color-primary) !important;
    background:  var(--color-primary-select) !important;
}

.availableTarget{
    outline: 0.125em dashed var(--color-stroke-lowlight);
    background: var(--color-fill-element-lowlight);
}

.pageCover{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;
}